import { DOCUMENT } from '@angular/common';
import { computed, effect, inject, Injectable, Injector, RendererFactory2, runInInjectionContext } from '@angular/core';

import { CurrentSiteState } from '@ppg/configuration';
import { APP_THEMING_DEFAULT } from '@ppg/core/constants';
import { LoggerService } from '@ppg/core/logger';

import { ThemesProvider } from '../providers/themes-provider.model';

@Injectable({
  providedIn: 'root',
})
export class ThemesService implements ThemesProvider {
  // after upgrading @pds packages this version needs to be updated because of cdn cache
  readonly #themeVersion = '0.12.0';
  readonly #loggerService = inject(LoggerService);
  readonly #document = inject(DOCUMENT);
  readonly #currentSiteState = inject(CurrentSiteState);
  readonly injector = inject(Injector);
  readonly #renderer = inject(RendererFactory2).createRenderer(null, null);

  readonly theme = computed(() => {
    const theme = this.#currentSiteState.themeName();
    if (theme) {
      return theme.replace(/\s+/g, '-').toLowerCase();
    }
    return APP_THEMING_DEFAULT;
  });
  #initialized = false;

  loadTheme(): void {
    if (this.#initialized) {
      return;
    }
    this.#initialized = true;
    runInInjectionContext(this.injector, () => {
      effect(() => {
        this.#loggerService.info('[ThemesService] Starting theme update');
        this.#setTheme(this.theme());
      });
    });
    this.#loggerService.info('[ThemesService] Theme updater initialized');
  }

  #setTheme(themeName: string): void {
    const themeSrc = this.#getThemeSrcElement();
    const themeUrl = `${themeName}.css?v=${this.#themeVersion}`;
    this.#loggerService.info(`[ThemesService] New theme url: ${themeUrl}`);

    if (themeSrc) {
      if (themeSrc.href !== themeUrl) {
        this.#loggerService.info(
          `[ThemesService] Theme link found, updating old theme: ${themeSrc.href} to new one ${themeUrl}`,
        );
        themeSrc.href = themeUrl;
      } else {
        this.#loggerService.info(`[ThemesService] Theme link found, url is the same, skipping theme updating`);
      }
    } else {
      this.#loggerService.info(`[ThemesService] Theme link item missed, creating...`);
      const head = this.#document.getElementsByTagName('head')[0];
      const link = this.#renderer.createElement('link');
      this.#renderer.setAttribute(link, 'id', 'app-theme');
      this.#renderer.setAttribute(link, 'rel', 'stylesheet');
      this.#renderer.setAttribute(link, 'type', 'text/css');
      this.#renderer.setAttribute(link, 'href', themeUrl);
      this.#renderer.appendChild(head, link);
    }
  }

  #getThemeSrcElement(): HTMLLinkElement {
    return this.#document.getElementById('app-theme') as HTMLLinkElement;
  }
}
